@media (max-width: 600px){
    .leftCn{
        margin-top: 10px !important;
    }
}
/* HomeR.css */

.hakx {
    text-shadow: 0 0 12px rgba(0, 123, 255, 0.7);
                 
                 
                 
  }
  
@media (max-width: 1010px){
    .hakx{
        font-size: 62px !important;
    }

    .inter{
        font-size: 18px !important;
    }
}

@media (max-width: 389px){
    .hakx{
        font-size: 52px !important;
    }

    .inter{
        font-size: 17px !important;
    }
}


@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');


/* .leftCn{
    top:400px !important;
} */

/* .leftCn, .rightCn {
    padding-left: 0; 
    padding-right: 0; 
  } */
/* 
  @media (min-width: 1100px){
    img{
        left: -40%;

    }
  }   */


  .custom-button1 {
    background-color: #1e99f1; 
    border-color:  #1e99f1 ;
    border-width: 3px;
    border-radius: 50px;
    font-size: 18px;
    font-weight: bolder;
    color: black;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin-top: 10px;
    transition-duration: 0.4s;
    cursor: pointer;
  }
  
  .custom-button1:hover {
    font-weight: bolder;
    background-color: rgba(46, 46, 46, 0.5);
    color: white;
    border: 3px solid #1e99f1;
  }

  @media (min-width: 700px) and (max-width: 1000px) {
    .leftCn{
      margin-top: 80px !important;
    }

    .img2{
      width: 250% !important;
    }
  }
  
  .custom-button, .custom-button1 {
    width: 200px;
    white-space: nowrap; /* Prevent text wrapping inside the button */
    position: relative;
    z-index: 10; /* Ensure buttons are on top of other elements */
  }